/* ANIMATIONS */
@keyframes smooth-appear {
  from {
    opacity: 0;
  }
  to {
    opacity:1;
  }
}

@keyframes smooth-glide {
  from {
    padding-top: 20vh;
  }
  to {
    padding-top: 1vh;
  }
}

/* DESKTOP VERSION */
.App {
  text-align: center;
}

.appBox {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.section {
  width: 100%;
  justify-content: center;
  padding: 4vw;
}

.eventCard {
  text-align: center;
  margin: auto;
  animation: smooth-appear 3s ease forwards;
}

.eventGrid {
  /* display: flex; */
  justify-content: space-evenly;
  margin-bottom: 8vw;
}

.eventBox {
  padding: 2vw;
  opacity: 0;
  animation: smooth-appear 1s ease forwards;
}

.leftborder {
  border-top: 1px solid #d4d4d4;
}

.coupleName {
  color: darkgoldenrod;
  font-size: 60px;
  font-family: "Alex Brush";
  animation: smooth-appear 4s ease forwards;
}

.rsvpForm {
  text-align: left;
  padding: 0 8vw;
  margin: auto;
}

.rsvpSection {
  border-left: 1px solid #d4d4d4;
  margin: auto;
}

#rsvpText {
  font-weight: 500;
  font-size: clamp(24px, 1.8vw, 32px);
}

input {
  width: 100%;
  margin-top: 1vw;
  box-sizing: border-box;
  line-height: 1.8;
  border-radius: 0;
  /* border-radius: 5px;
  border: 1px solid #aeaeae; */
  border-top: 0;
  border-bottom: 1px solid #aeaeae;
  border-left: 0;
  border-right: 0;
}

input:focus-visible {
  outline: 0;
  border-bottom: 1px solid grey;
}

.field {
  padding: 1vw 0;
  animation: smooth-appear 1s ease forwards;
}

.multipleFields {
  display: flex;
  justify-content: space-between;
}

.spouseField {
  margin: auto 0;
}

.addBtn,
.subtractBtn {
  width: 4vh;
  height: 4vh;
  background-color: #e1e1e1;
  border: none;
  margin-left: 1vw;
  border-radius: 10px;
  font-size: 20px;
  text-align: center;
}

.addedInputs {
  animation: smooth-appear 1s ease forwards;
}

.rsvpBtn {
  padding: 2vw;
  width: 100%;
  /* background-color: #d8bc49; */
  background-color: #cc9a02;
  border: none;
  border-radius: 5px;
  margin-bottom: 5vh;
  margin-top: 2vh;
  font-size: 18px;
  color: white;
}

.childrenBlurb {
  font-size: 9px;
}

.eventTitle {
  font-family: "Alex Brush";
  font-weight: 500;
  font-size: clamp(22px, 4vw, 26px);
}

.details {
  font-size: clamp(14px, 2vw, 18px);
}

.eventCheckboxGrid {
  display: flex;
  justify-content: space-evenly;
}

.eventField {
  animation: smooth-appear 1s ease forwards;
  border: 1px solid #aeaeae;
  padding: 0.5vw 2vw;
  border-radius: 10px;
}

.eventClicked {
  animation: smooth-appear 1s ease forwards;
  border: 1px solid #e1e1e1;
  background-color: #e1e1e1;
  padding: 0.5vw 2vw;
  border-radius: 10px;
}

.eventCheck {
  margin-top: 0;
  margin-right: 0.5vw;
}

.thankyouText {
  font-size: 40px;
  font-family: 'Alex Brush';
}

.receivedBox {
  padding: 4vh 0vw;
  animation: smooth-appear 2s ease forwards;
}

.errorMsg {
  display: block;
  text-align: center;
  padding: 1vw;
  background-color: red;
  color: white;
  font-family: 'PT Sans';
  border-radius: 5px;
  margin-bottom: 2vh;
}

.errorContent {
  display: flex;
  justify-content: center;
  img {
    margin-right: 2vw;
  }
}

.noError {
  display: none;
}

.guestCount {
  margin-top: 5%;
}

/* MOBILE VERSION */
@media (max-width: 700px) {
  .appBox {
    flex-direction: column;
  }

  .section {
    width: auto;
    min-height: 50vh;
  }

  .adjustedTop {
    padding-top: 20vh;
  }

  .readjustedTop {
    animation: smooth-glide 0.5s ease forwards; 
  }

  .rsvpSection {
    border-top: 1px solid #d4d4d4;
    background-color: #fff6e3;
    /* background-color: #EDE6D6; */
    margin: 0;
  }

  input {
    background-color: #fff6e3;
    /* background-color: #EDE6D6; */
  }

  .addBtn,
  .subtractBtn {
    background-color: #cc9a02;
    color: white;
  }

  .rsvpForm {
    padding: 0 12vw;
  }

  .field {
    padding: 2vw 0;
  }

  .childrenBlurb {
    margin-top: 3%;
  }

  .eventGrid {
    margin-bottom: 8vw;
  }

  .eventField {
    border: 1px solid #cc9a02;
    padding: 2vw 4vw;
  }
  
  .eventClicked {
    border: 1px solid #cc9a02;
    background-color: #cc9a02;
    color: white;
    padding: 2vw 4vw;
  }
}
